import React from "react";
import PropTypes from "prop-types";
import { Helmet } from "react-helmet";
import { graphql, Link } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Layout from "../components/Layout";
import Content, { HTMLContent } from "../components/Content";
import FullWidthMedia from "../components/FullWidthMedia";

// eslint-disable-next-line
export const PakketPostTemplate = ({
  content,
  contentComponent,
  data,
  description,
  title,
  helmet,
  color,
}) => {
  const PostContent = contentComponent || Content;

  return (
    <section>
      {helmet || ""}

      <div className="columns" style={{ margin: "0" }} id="main">
        <div className="column is-4" style={{ backgroundColor: color }}>
          <div className="container content">
            <div className="columns">
              <div className="column is-8 is-offset-2 pakket-list">
                <hr className="solid"></hr>
                <h4 className="title is-size-3 is-uppercase">toegang voor</h4>
                <ul>
                  {data.access.map((item, i) => (
                    <li className="is-capitalized theme-body" key={i}>
                      {item}
                    </li>
                  ))}
                </ul>
                <h4 className="title is-size-3 is-uppercase">items</h4>
                <ul>
                  {data.items.map((item, i) => (
                    <li className="is-capitalized theme-body" key={i}>
                      {item}
                    </li>
                  ))}
                </ul>
                <h4 className="title is-size-3 is-uppercase">inclusief</h4>
                <ul>
                  {data.includes.map((item, i) => (
                    <li className="is-capitalized theme-body" key={i}>
                      {item}
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="column is-8" style={{ backgroundColor: "#00412D" }}>
          <div className="container content">
            <div className="columns">
              <div className="column is-10">
                <div className="section">
                  <h2 className="title is-size-2 has-text-weight-bold is-bold-light">
                    €{data.price},-
                  </h2>
                  <p className="theme-body">
                    Besparing als je dit pakket koopt: {data.savings} euro{" "}
                    <br /> ten opzichte van losse tickets en munten
                  </p>
                  <hr className="solid" />
                  <h2 className="title is-size-3">{data.definition}</h2>
                  <PostContent className="theme-body" content={content} />
                  <Link
                    to="/vragen"
                    className="more"
                    style={{ marginTop: "1.5rem" }}
                  >
                    Ik heb nog een vraag.
                  </Link>
                  <hr className="solid" />
                  <p
                    className="title is-size-3"
                    style={{
                      fontFamily: "Athelas",
                      fontStyle: "italic",
                      fontWeight: "500",
                    }}
                  >
                    Maandelijkse investering €
                    {(data.price / data.periods).toFixed(0)} <br /> Aantal
                    maanden: {data.periods} <br /> Totale investering €
                    {data.price},-{" "}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="container content">
            <div className="columns">
              <div className="column is-10">
                <div className="section promote">
                  {data.promote && data.active && (
                    <div>
                      <Link to="/pakketten/gers" className="more">
                        Dorst naar gers?
                      </Link>
                    </div>
                  )}
                  {data.active && (
                    <Link
                      to="/pakketten/afsluiten"
                      state={{
                        pakket: {
                          title: data.title,
                          price: data.price,
                          periods: data.periods,
                          periodprice: (data.price / data.periods).toFixed(2),
                        },
                        modal: true,
                        noScroll: true,
                      }}
                      className="promote-button"
                      style={{ backgroundColor: color }}
                    >
                      {data.catch}
                    </Link>
                  )}
                  {!data.active && (
                    <div
                      className="promote-button"
                      style={{ backgroundColor: color }}
                    >
                      verkoop pakketten gesloten!
                    </div>
                  )}
                  <hr className="solid" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

PakketPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
};

const PakketPost = ({ data }) => {
  const { markdownRemark: post } = data;
  const heroImage =
    getImage(post.frontmatter.featuredimage) || post.frontmatter.featuredimage;

  return (
    <Layout>
      <FullWidthMedia
        img={heroImage}
        video={post.frontmatter.videourl}
        title={post.frontmatter.title}
        subheading={'"' + post.frontmatter.heading + '"'}
        height={"90vh"}
        color="#E8E100"
        overlaycolor={post.frontmatter.accentcolor}
        anchor={post.fields.slug.slice(0, -1) + "#main"}
      />
      <PakketPostTemplate
        content={post.html}
        data={post.frontmatter}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Pakket">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        title={post.frontmatter.title}
        color={post.frontmatter.accentcolor}
      />
    </Layout>
  );
};

PakketPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
};

export default PakketPost;

export const pageQuery = graphql`
  query PakketPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      fields {
        slug
      }
      frontmatter {
        date(formatString: "MMMM DD, YYYY")
        title
        featuredimage {
          childImageSharp {
            gatsbyImageData(quality: 100, layout: FULL_WIDTH)
          }
        }
        definition
        heading
        accentcolor
        videourl
        access
        items
        includes
        heading
        price
        savings
        periods
        catch
        promote
        active
      }
    }
  }
`;
