import React from "react";
import PropTypes from "prop-types";
import { GatsbyImage } from "gatsby-plugin-image";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import useFitText from "use-fit-text";

export default function FullWidthMedia(props) {
  const {
    height = 500,
    img,
    video,
    title,
    subheading,
    imgPosition = "center",
    color = "#00412D",
    overlaycolor,
    darken = false,
    anchor,
    maxFont = 2000,
  } = props;

  const { fontSize, ref } = useFitText({ maxFontSize: maxFont });

  return (
    <React.Fragment>
      <div
        className="margin-top-0"
        style={{
          display: "grid",
          alignItems: "center",
          position: "relative",
        }}
      >
        {video ? (
          <>
            <video
              autoPlay
              muted
              loop
              id="myVideo"
              style={{
                gridArea: "1/1",
                // You can set a maximum height for the image, if you wish.
                maxHeight: height,
                height: height,
                width: "100%",
                filter: darken ? "brightness(50%)" : false,
                objectFit: "cover",
              }}
            >
              <source src={video} type="video/mp4" />
            </video>
            {/* <div
              style={{
                position: "absolute",
                width: "100%",
                height: "100%",
                zIndex: 0,
                background: overlaycolor,
                opacity: 1,
                mixBlendMode: "overlay",
              }}
            ></div> */}
          </>
        ) : img ? (
          img?.url ? (
            <img
              src={img}
              objectFit={"cover"}
              objectPosition={imgPosition}
              style={{
                gridArea: "1/1",
                // You can set a maximum height for the image, if you wish.
                height: height,
                width: "100%",
                filter: darken ? "brightness(50%)" : false,
              }}
              // You can optionally force an aspect ratio for the generated image
              aspectratio={3 / 1}
              // This is a presentational image, so the alt should be an empty string
              alt=""
              formats={["auto", "webp", "avif"]}
            />
          ) : (
            <>
              <GatsbyImage
                image={img}
                objectFit={"contains"}
                objectPosition={imgPosition}
                style={{
                  gridArea: "1/1",
                  // You can set a maximum height for the image, if you wish.
                  maxHeight: height,
                  height: height,
                  filter: darken ? "brightness(50%)" : false,
                }}
                layout="fullWidth"
                // You can optionally force an aspect ratio for the generated image
                aspectratio={3 / 1}
                // This is a presentational image, so the alt should be an empty string
                alt=""
                formats={["auto", "webp", "avif"]}
              />

              <div
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                  zIndex: 0,
                  background: overlaycolor,
                  opacity: 1,
                  mixBlendMode: "overlay",
                }}
              ></div>
            </>
          )
        ) : (
          <div
            style={{
              width: "100%",
              minHeight: height,
              background: "black",
              gridArea: "1/1",
            }}
          ></div>
        )}
        {anchor && (
          <AnchorLink to={anchor} className="scroll-button round-button">
            <div className="round">
              <div className="arrow"></div>
            </div>
          </AnchorLink>
        )}
        {(title || subheading) && (
          <div
            style={{
              // By using the same grid area for both, they are stacked on top of each other
              gridArea: "1/1",
              position: "relative",
              // This centers the other elements inside the hero component
              placeItems: "center",
              display: "grid",
            }}
          >
            {/* Any content here will be centered in the component */}
            {title && (
              <h1
                ref={ref}
                style={{
                  fontSize,
                  width: "80%",
                  maxHeight: "49vh",
                  textAlign: "center",
                  fontFamily: "naguboty",
                  textTransform: "uppercase",
                  color: color,
                  lineHeight: "normal",
                  letterSpacing: ".6rem",
                }}
              >
                {title}
              </h1>
            )}
            {subheading && (
              <h3
                className="has-text-weight-bold has-text-centered"
                style={{
                  width: "40%",
                  minWidth: "300px",
                  fontFamily: "athelas",
                  fontStyle: "italic",
                  fontWeight: "500",
                  color: "white",
                  padding: "0.25rem",
                  lineHeight: "calc(2rem + 1.5vw)",
                  fontSize: "calc(2rem + 1.5vw)",
                }}
              >
                {subheading}
              </h3>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

FullWidthMedia.propTypes = {
  img: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  title: PropTypes.string,
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  subheading: PropTypes.string,
  color: PropTypes.string,
  overlaycolor: PropTypes.string,
  darken: PropTypes.bool,
  anchor: PropTypes.string,
};
